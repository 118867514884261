import '../styles/globals.css';
import 'react-image-lightbox/style.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { ThemeProvider } from 'next-themes';
import Layout from '../components/layout';
import BasicLayout from '../components/basicLayout';
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import { useRouter } from 'next/router';
import Meta from '../components/Meta';
import UserContext from '../components/UserContext';
import { useRef } from 'react';
import { AuthUserProvider } from '../context/AuthUserContext';
import FacebookChat from '../components/FacebookChat';

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });
  return (
    <>
      <Meta title='Mi Foto de Perfil | Genera imágenes con inteligencia artificial de ti con varios estilos y temáticas.' />
      <FacebookChat />
      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute='class'>
          <UserContext.Provider value={{ scrollRef: scrollRef }}>
            <AuthUserProvider>
              {pid === '/login' || pid.includes('/styles-selection') ? (
                <BasicLayout>
                  <Component {...pageProps} />
                </BasicLayout>
              ) : (
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              )}
            </AuthUserProvider>
          </UserContext.Provider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default MyApp;
