const socialIcons = [
  // {
  //   id: 1,
  //   href: 'https://www.facebook.com',
  //   text: 'facebook',
  // },
  // {
  //   id: 2,
  //   href: 'https://www.twitter.com',
  //   text: 'twitter',
  // },
  // {
  //   id: 3,
  //   href: 'https://www.discord.com',
  //   text: 'discord',
  // },
  {
    id: 4,
    href: 'https://www.instagram.com/mifotodeperfil_',
    text: 'instagram',
  },
  {
    id: 5,
    href: 'https://www.tiktok.com/@mifotodeperfil_',
    text: 'tiktok',
  },
];

export { socialIcons };
