import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { customTextModalHide } from '../../redux/counterSlice';

const CustomTextModal = () => {
  const { customTextModal } = useSelector((state: any) => state.counter);
  const dispatch = useDispatch();
  const { custom_text_data } = useSelector((state: any) => state.counter);

  return (
    <div>
      <div className={customTextModal ? 'modal fade show block' : 'modal fade'}>
        <div className='modal-dialog max-w-2xl'>
          <div className='modal-content'>
            <div className='modal-header justify-center'>
              <h5 className='modal-title text-center' id='placeBidLabel'>
                {custom_text_data[0]}
              </h5>
              <button
                type='button'
                className='btn-close'
                onClick={() => dispatch(customTextModalHide())}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  className='fill-jacarta-700 h-6 w-6 dark:fill-white'
                >
                  <path fill='none' d='M0 0h24v24H0z'></path>
                  <path d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z'></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className='modal-body p-6'>
              <div className='relative mb-2 flex items-center text-center overflow-hidden rounded-lg text-lg'>
                {custom_text_data[1]}
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className='modal-footer'>
              <div className='flex items-center justify-center space-x-4'>
                <button
                  onClick={() => dispatch(customTextModalHide())}
                  type='button'
                  className='bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all'
                >
                  {custom_text_data[2]}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTextModal;
