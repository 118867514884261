import Script from 'next/script';
import NoSSR from '@mui/material/NoSsr';

const FacebookChat = () => {
  return (
    <NoSSR>
      <>
        <div id='fb-root'></div>
        <div id='fb-customer-chat' className='fb-customerchat'></div>
        <Script strategy='lazyOnload' id='facebook-chat'>
          {`
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", "106312548995721");
            chatbox.setAttribute("attribution", "biz_inbox");
      
            window.fbAsyncInit = function() {
              FB.init({
                xfbml            : true,
                version          : 'v16.0'
              });
            };
      
            (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
               js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        `}
        </Script>
      </>
    </NoSSR>
  );
};

export default FacebookChat;
