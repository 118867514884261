import React from 'react';
import Link from 'next/link';
import { socialIcons } from '../data/footer_data';

const Footer = () => {
  return (
    <footer className='dark:bg-jacarta-900 page-footer bg-white'>
      <div className='container'>
        <div className='grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12 my-center'>
          <div className='col-span-6 md:col-span-4 my-center'>
            {/* <!-- Logo --> */}
            <Link href='#' className='mb-6 inline-block'>
              <img
                src='/images/logo.svg'
                className='max-h-12 dark:hidden'
                alt='Mi Foto de Perfil'
              />
            </Link>

            <Link href='#' className=' mb-6 inline-block'>
              <img
                src='/images/logo_white.svg'
                className='hidden max-h-12 dark:block mb-6'
                alt='Mi Foto de Perfil'
              />
            </Link>
            <p className='dark:text-jacarta-300 mb-12 text-center'>
              Crea tus propias fotos de perfil para publicar y compartir con amigos y familia.
            </p>

            {/* <!-- Socials --> */}
            <div className='flex space-x-5'>
              {socialIcons.map((item) => {
                const { id, href, text } = item;
                return (
                  <Link
                    href={href}
                    key={id}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='group cursor-pointer'
                  >
                    <svg className='icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white'>
                      <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                    </svg>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>

        <div className='flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0'>
          <span className='dark:text-jacarta-400 text-sm'>
            <span>© {new Date().getFullYear()} Mi Foto de Perfil </span>
          </span>

          <ul className='dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm'>
            <li>
              <Link href='/terms' className='hover:text-accent dark:hover:text-white'>
                Terminos y condiciones
              </Link>
            </li>
            <li>
              <Link href='/terms' className='hover:text-accent dark:hover:text-white'>
                Política de privacidad
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
