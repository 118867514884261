import { useState, useEffect } from 'react';
import { auth } from '../lib/firebase';
import {
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  User,
} from 'firebase/auth';

export interface AuthUser {
  uid: string;
  email: string;
  emailVerified: boolean;
  providerData: any;
  displayName: string;
  photoURL: string;
}

const formatAuthUser = (user: User): Partial<AuthUser> => ({
  uid: user.uid,
  email: user.email,
  emailVerified: user.emailVerified,
  providerData: user.providerData,
  displayName: user.displayName,
  photoURL: user.photoURL,
});

export default function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: process.env.NEXT_PUBLIC_BASE_URL + '/login/email_confirm',
    // This must be true.
    handleCodeInApp: true,
  };

  const authStateChanged = async (authState) => {
    // console.log(authState, 'authState');
    if (!authState) {
      setAuthUser(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    const formattedUser = formatAuthUser(authState);
    setAuthUser(formattedUser);
    setLoading(false);
  };

  const clear = () => {
    setAuthUser(null);
    setLoading(true);
  };
  const getUserState = (): AuthUser => {
    return authUser;
  };
  const getLoadingState = (): boolean => {
    return loading;
  };
  const mySignInWithEmailAndPassword = async (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password);

  const myCreateUserWithEmailAndPassword = async (email: string, password: string) =>
    createUserWithEmailAndPassword(auth, email, password);

  const mySendSignInLinkToEmail = async (email: string) => {
    window.localStorage.setItem('emailForSignIn', email);
    return sendSignInLinkToEmail(auth, email, actionCodeSettings);
  };

  const mySignInWithGooglePopup = async () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const signOut = () => auth.signOut().then(clear);

  // listen for Firebase state change
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    loading,
    mySignInWithEmailAndPassword,
    myCreateUserWithEmailAndPassword,
    mySignInWithGooglePopup,
    mySendSignInLinkToEmail,
    signOut,
    getUserState,
    getLoadingState,
  };
}
