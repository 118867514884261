import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mblMenu: false,
  dropdown: false,
  customTextModal: false,
  custom_text_data: [],
  buyModal: false,
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    openMblMenu: (state) => {
      state.mblMenu = true;
    },
    closeMblMenu: (state) => {
      state.mblMenu = false;
    },
    customTextModalShow: (state, action) => {
      state.customTextModal = true;
      state.custom_text_data = action.payload;
    },
    customTextModalHide: (state) => {
      state.customTextModal = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openMblMenu, closeMblMenu, customTextModalShow, customTextModalHide } =
  counterSlice.actions;

export default counterSlice.reducer;
