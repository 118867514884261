/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect } from 'react';
import { useTheme } from 'next-themes';
import Link from 'next/link';
import MblNavbar from './mblNavbar';
import { useSelector, useDispatch } from 'react-redux';
import { openMblMenu } from '../redux/counterSlice';
import { useRouter } from 'next/router';
import { throttle } from 'lodash';

const Navbar = () => {
  const { theme, setTheme } = useTheme();
  const [isNavBarHidden, setIsNavBarHidden] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const { mblMenu } = useSelector((state: any) => state.counter);
  const dispatch = useDispatch();
  const router = useRouter();
  const pid = router.asPath;

  const handleTheme = () => {
    if (theme == 'dark' && pid === '/home/home_8') {
      setTheme('dark');
    } else if (theme == 'dark' && pid !== '/home/home_8') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  };

  // const router = useRouter();

  useEffect(() => {
    if (theme === 'light' && pid === '/home/home_8') {
      setTheme('dark');
    }
  }, [pid, setTheme, theme]);

  // TODO: show nav is user is scrolling up, hide if scrolling down
  useEffect(() => {
    window.addEventListener(
      'scroll',
      throttle(function (e) {
        const _scrollY = window.scrollY;
        if (_scrollY >= 100) {
          setIsNavBarHidden(true);
          // if (_scrollY < scrollY) {
          //   setIsNavBarHidden(true);
          // }
        } else {
          setIsNavBarHidden(false);
        }

        setScrollY(_scrollY);
      }, 200)
    );
  }, []);

  if (mblMenu) {
    return (
      <div>
        <header
          className={
            isNavBarHidden
              ? 'hidden'
              : 'js-page-header fixed top-0 z-1 w-full backdrop-blur transition-colors h-full'
          }
        >
          {pid === '/platform_status' ? (
            <div className='container'>
              <div className='flex items-center py-[1.5625rem] lg:py-[1.8125rem]'>
                {/* <!-- Logo --> */}
                <Link href='/' className='shrink-0 lg:mr-14'>
                  <img src='/images/logo.svg' className='max-h-12 dark:hidden' />
                  <img src='/images/logo_white.svg' className='hidden max-h-12 dark:block' />
                </Link>

                <span className='font-display mt-1 hidden text-lg font-semibold lg:inline-block'>
                  Status
                </span>

                <Link
                  href='#'
                  className='bg-accent shadow-accent-volume hover:bg-accent-dark ml-auto inline-block rounded-full py-2.5 px-8 text-center text-sm font-semibold text-white transition-all'
                >
                  <span className='hidden lg:block'>Subscribe to Updates</span>
                  <span className='lg:hidden'>Subscribe</span>
                </Link>
              </div>
            </div>
          ) : (
            <div className='flex items-center px-6 py-6 xl:px-24 '>
              {/* <!-- Logo --> */}

              <Link href='/' className='shrink-0 block dark:hidden'>
                <img src='/images/logo.svg' alt='' className='max-h-12 h-auto' />
              </Link>
              <Link href='/' className='shrink-0 hidden dark:block'>
                <img src='/images/logo_white.svg' className='max-h-12 h-auto' />
              </Link>

              {/* <!-- Menu / Actions --> */}
              <MblNavbar theme={handleTheme} />

              {/* <!-- Mobile Menu Actions --> */}
              <div className='ml-auto flex lg:hidden'>
                {/* <!-- Profile --> */}
                <Link
                  href='/'
                  className='border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                  aria-label='profile'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z' />
                  </svg>
                </Link>

                {/* <!-- Dark Mode --> */}
                <button
                  className='js-dark-mode-trigger border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                  onClick={() => {
                    handleTheme();
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='fill-jacarta-700 dark-mode-light h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z' />
                  </svg>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='fill-jacarta-700 dark-mode-dark hidden h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z' />
                  </svg>
                </button>

                {/* <!-- Mobile Menu Toggle --> */}
                <button
                  className='js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                  aria-label='open mobile menu'
                  onClick={() => dispatch(openMblMenu())}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z' />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </header>
      </div>
    );
  } else {
    return (
      <div>
        <header
          className={
            isNavBarHidden
              ? 'hidden'
              : 'js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors'
          }
        >
          {pid === '/platform_status' ? (
            <div className='container'>
              <div className='flex items-center py-[1.5625rem] lg:py-[1.8125rem]'>
                {/* <!-- Logo --> */}
                <Link href='/' className='shrink-0 lg:mr-14'>
                  <img src='/images/logo.svg' className='max-h-12 dark:hidden' />
                  <img src='/images/logo_white.svg' className='hidden max-h-12 dark:block' />
                </Link>

                <span className='font-display mt-1 hidden text-lg font-semibold lg:inline-block'>
                  Status
                </span>

                <Link
                  href='#'
                  className='bg-accent shadow-accent-volume hover:bg-accent-dark ml-auto inline-block rounded-full py-2.5 px-8 text-center text-sm font-semibold text-white transition-all'
                >
                  <span className='hidden lg:block'>Subscribe to Updates</span>
                  <span className='lg:hidden'>Subscribe</span>
                </Link>
              </div>
            </div>
          ) : (
            <div className='flex items-center p-3 md:p-6 xl:px-24 '>
              {/* <!-- Logo --> */}

              <Link href='/' className='shrink-0'>
                <img src='/images/logo.svg' alt='' className='max-h-12 h-auto dark:hidden' />

                <img src='/images/logo_white.svg' className='max-h-12 h-auto hidden dark:block' />
              </Link>

              {/* <!-- Menu / Actions --> */}
              <MblNavbar theme={handleTheme} />

              {/* <!-- Mobile Menu Actions --> */}
              <div className='ml-auto flex lg:hidden'>
                {/* <!-- Profile --> */}
                {/* {home3 ? (
                  <Link href='/profile/user_avatar'>
                    <a
                      className='border-jacarta-100 focus:bg-accent group hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent border-transparent bg-white/[.15]'
                      aria-label='profile'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width='24'
                        height='24'
                        className=' h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white fill-white'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z' />
                      </svg>
                    </a>
                  </Link>
                ) : (
                  <Link href='/profile/user_avatar'>
                    <a
                      className='border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                      aria-label='profile'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width='24'
                        height='24'
                        className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
                      >
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z' />
                      </svg>
                    </a>
                  </Link>
                )} */}

                {/* <!-- Dark Mode --> */}
                <button
                  className='js-dark-mode-trigger border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                  onClick={() => {
                    handleTheme();
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='fill-jacarta-700 dark-mode-light h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z' />
                  </svg>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    className='fill-jacarta-700 dark-mode-dark hidden h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white'
                  >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z' />
                  </svg>
                </button>

                {/* <!-- Mobile Menu Toggle --> */}
                {/* {home3 ? (
                  <button
                    className='js-mobile-toggle border-jacarta-100 focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent border-transparent bg-white/[.15]'
                    aria-label='open mobile menu'
                    onClick={() => {
                      dispatch(openMblMenu());
                      console.log('open mbl nav');
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      width='24'
                      height='24'
                      className=' h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white fill-white'
                    >
                      <path fill='none' d='M0 0h24v24H0z' />
                      <path d='M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z' />
                    </svg>
                  </button>
                ) : (
                  <button
                    className='js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
                    aria-label='open mobile menu'
                    onClick={() => {
                      dispatch(openMblMenu());
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      width='24'
                      height='24'
                      className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
                    >
                      <path fill='none' d='M0 0h24v24H0z' />
                      <path d='M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z' />
                    </svg>
                  </button>
                )} */}
              </div>
            </div>
          )}
        </header>

        {/* <Wallet_modal /> */}
      </div>
    );
  }
};

export default Navbar;
