import { createContext, useContext, Context } from 'react';
import useFirebaseAuth, { AuthUser } from '../lib/useFirebaseAuth';
import { UserCredential } from 'firebase/auth';

// create interface for authUserContext
interface AuthUserContextInterface {
  authUser: AuthUser | null;
  loading: boolean;
  mySignInWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>;
  myCreateUserWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>;
  mySignInWithGooglePopup: () => Promise<UserCredential>;
  signOut: () => Promise<void>;
}

const authUserContext = createContext<AuthUserContextInterface>({
  authUser: null,
  loading: true,
  // mySignInWithEmailAndPassword as async funtion returning UserCredential in createContext
  mySignInWithEmailAndPassword: async (email: string, password: string) => {
    return {} as UserCredential;
  },
  myCreateUserWithEmailAndPassword: async (email: string, password: string) => {
    return {} as UserCredential;
  },
  mySignInWithGooglePopup: async () => {
    return {} as UserCredential;
  },
  signOut: async () => {},
});

export function AuthUserProvider({ children }) {
  const auth = useFirebaseAuth();
  return <authUserContext.Provider value={auth}>{children}</authUserContext.Provider>;
}
// custom hook to use the authUserContext and access authUser and loading
export const useAuth = () => useContext(authUserContext);
