import CustomTextModal from './modal/customTextModal';

export default function BasicLayout({ children }) {
  return (
    <>
      <CustomTextModal />
      {children}
    </>
  );
}
