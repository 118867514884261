import React from 'react';
import Head from 'next/head';
import Script from 'next/script';

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel='icon' href='/favicon.png' />
        <meta name='description' content={desc} />
        <meta name='keyword' content={keyword} />

        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image'
          content='https://firebasestorage.googleapis.com/v0/b/mifotodeperfil-711e0.appspot.com/o/Group%203.jpg?alt=media&token=61b62b43-b17b-4268-abab-848e2bb75ea7'
        />
        <meta
          name='twitter:title'
          content='Mi Foto de Perfil | Generador de imágenes con inteligencia artificial'
        />
        <meta
          name='twitter:description'
          content='Genera imágenes increíbles de ti con inteligencia artificial para compartir en tus redes sociales o con familia y amigos.'
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://mifotodeperfil.com' />
        <meta
          property='og:title'
          content='Mi Foto de Perfil | Generador de imágenes con inteligencia artificial'
        />
        <meta
          property='og:description'
          content='Genera imágenes increíbles de ti con inteligencia artificial para compartir en tus redes sociales o con familia y amigos.'
        />
        <meta
          property='og:image'
          content='https://firebasestorage.googleapis.com/v0/b/mifotodeperfil-711e0.appspot.com/o/Group%203.jpg?alt=media&token=61b62b43-b17b-4268-abab-848e2bb75ea7'
        />
      </Head>
      <Script id='clarity-tag'>
        {`
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "f1443rir6a");
        `}
      </Script>
    </div>
  );
};

Meta.defaultProps = {
  title: 'Mi Foto de Perfil | Generador de imágenes con inteligencia artificial',
  keyword:
    'fotos con inteligencia artificial, ia, ai , imágenes con inteligencia artificial, foto de perfil con inteligencia artificial, foto de perfil, imagen de perfil, avatar, avatar con inteligencia artificial',
  desc: 'Genera imágenes increíbles de ti con inteligencia artificial para compartir en tus redes sociales o con familia y amigos.',
};

export default Meta;
